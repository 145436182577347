import { RecordContextProvider, useRecordContext } from "react-admin";
import type { EndUser } from "../type";
import { ContactValidationStatusField } from "./ContactValidationStatusField";

export const ContactLastValidationStatusField = ({
  provider,
  label,
}: {
  provider: "brevo" | "captainVerify";
  label?: string;
}) => {
  const record =
    useRecordContext<
      EndUser["contacts"][keyof EndUser["contacts"]]["all"][number]
    >();
  const status = record?.validationHistory?.findLast((v) =>
    v.status.startsWith(provider + ":"),
  )?.status;
  return (
    <RecordContextProvider value={status ? { status } : undefined}>
      <ContactValidationStatusField label={label} />
    </RecordContextProvider>
  );
};
