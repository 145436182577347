import { RecordContextProvider, useRecordContext } from "react-admin";
import type { Device } from "../../../../types";
import InfoList from "../../InfoList";
import { ComfortSlider } from "./ComfortSlider";
import { EditComfortDialog } from "./EditComfortDialog";
import { useComfortData } from "./useComfortData";

export const ComfortFields = () => {
  const record = useRecordContext<Device>();

  const { data: comfortConfig, error } = useComfortData(record?.id);

  return (
    <>
      {[
        {
          label: "Day Comfort",
          config: comfortConfig?.dayComfort,
          value: comfortConfig?.currentConfig.deltaDayTemperatureInC,
          source: "delta_day_temperature_in_c" as const,
          comfortType: "day" as const,
          description:
            "Ce paramètre augmente la température de boost par rapport à l'algorithme par défaut. Il s'agit de la valeur comfigurable dans l'app résident",
        },
        {
          label: "Night Comfort",
          config: comfortConfig?.nightComfort,
          value: comfortConfig?.currentConfig.deltaNightTemperatureInC,
          source: "delta_night_temperature_in_c" as const,
          comfortType: "night" as const,
          description:
            "Ce paramètre n'est à utiliser qu'exceptionnellement pour augmenter la température de base. Privilégier la confort de jour (température de boost) pour la majorité des cas (HC/HP & base).",
        },
      ].map(({ label, config, value, description, source, comfortType }) =>
        config || error ? (
          <RecordContextProvider
            value={{ id: record?.id, [source]: value ?? 0 }}
          >
            <InfoList.Item
              label={label}
              key={label}
              editDialog={
                config ? (
                  <EditComfortDialog
                    comfortType={comfortType}
                    minValue={config.minSelectableTemperatureInC}
                    maxValue={config.maxSelectableTemperatureInC}
                    recommendedValue={config.recommendedTemperatureInC}
                    initialValue={value}
                    description={description}
                    source={source}
                  />
                ) : undefined
              }
              withHistory={{
                resource: "device_comfort_config",
                attribute: "delta_day_temperature_in_c",
              }}
            >
              {config ? (
                <ComfortSlider
                  minValue={config.minSelectableTemperatureInC}
                  maxValue={config.maxSelectableTemperatureInC}
                  recommendedValue={config.recommendedTemperatureInC}
                  source={source}
                />
              ) : error ? (
                <>{error.message}</>
              ) : null}
            </InfoList.Item>
          </RecordContextProvider>
        ) : null,
      )}
    </>
  );
};
