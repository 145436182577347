import {
  Datagrid,
  EditButton,
  FilterButton,
  ImageField,
  List,
  NumberField,
  SearchInput,
  TextField,
} from "react-admin";
import { SyncHubspotButton } from "../../actions/SyncHubspot";
import { HubspotLink } from "../../fields/HubspotLink";
import { ActionsMenu } from "../../layout/actions-menu";
import { ClientCategory } from "./fields/ClientCategory";

export const OrganizationList = () => {
  return (
    <List
      perPage={50}
      filters={[<SearchInput source="q" alwaysOn />]}
      actions={
        <>
          <FilterButton />
          <ActionsMenu>
            <SyncHubspotButton />
          </ActionsMenu>
        </>
      }
    >
      <Datagrid bulkActionButtons={false}>
        <ImageField
          label="Logo"
          source="logoSrc"
          sx={{
            "& img": { maxHeight: 30, maxWidth: 100, objectFit: "contain" },
          }}
        />
        <TextField label="Name" source="name" />
        <ClientCategory label="Category" source="category" sortable />
        <HubspotLink label="Hubspot" source="hubspotURL" size={20} />
        <NumberField label="Projects" source="stats.projectCount" />
        <TextField label="Heater Count" source="heaterCount" />
        <NumberField label="Installed Devices" source="stats.deviceCount" />
        <NumberField label="Admins" source="adminsCount" />
        <NumberField label="Viewers" source="viewersCount" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
