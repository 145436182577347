import {
  Datagrid,
  FilterButton,
  List,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  TextField,
} from "react-admin";
import { SyncHubspotButton } from "../../actions/SyncHubspot";
import { HubspotLink } from "../../fields/HubspotLink";
import { ActionsMenu } from "../../layout/actions-menu";

const DealListActions = () => (
  <>
    <FilterButton />
    <ActionsMenu>
      <SyncHubspotButton />
    </ActionsMenu>
  </>
);

const dealFilters = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput
    label="Client"
    placeholder="Client"
    source="organizationId"
    reference="organizations"
    sort={{ field: "name", order: "ASC" }}
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
];

export const DealList = () => {
  return (
    <List
      perPage={100}
      sort={{ field: "createdAt", order: "DESC" }}
      filters={dealFilters}
      actions={<DealListActions />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField label="Name" source="name" />
        <HubspotLink source="hubspotURL" label="Hubspot" />
        <ReferenceField
          label="Client"
          reference="organizations"
          source="organizationId"
          link="show"
        >
          <TextField label="Name" source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
