import { green, purple, red } from "@mui/material/colors";
import { EnumField } from "../../../fields/EnumField";

export const ContactStatusField = ({
  label = "Status",
}: {
  label?: string;
}) => {
  return (
    <EnumField
      source="status"
      label={label}
      colors={{
        validated: green[800],
        deleted: red[800],
        invalid: purple[800],
      }}
    />
  );
};
