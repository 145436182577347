export enum ContactValidationStatus {
  Unknown = "unknown",
  Deleted = "deleted", // When deleted, the contact should not be used anymore
  BackOfficeGivenByUser = "backOffice:givenByUser",
  BackOfficeOtherReliable = "backOffice:otherReliable",
  BackOfficeOtherUnreliable = "backOffice:otherUnreliable",
  ResidentAppUnverified = "residentApp:unverified",
  InterventionInReport = "intervention:inReport",
  BrevoDelivered = "brevo:delivered",
  BrevoUniqueOpened = "brevo:uniqueOpened",
  BrevoClick = "brevo:click",
  BrevoHardBounce = "brevo:hardBounce",
  BrevoInvalidEmail = "brevo:invalidEmail",
  BrevoError = "brevo:error",
  BrevoSpam = "brevo:spam",
  BrevoUnsubscribed = "brevo:unsubscribed",
  BrevoBlocked = "brevo:blocked",
  AuthOTPVerified = "auth:otpVerified",
  CaptainVerifyInvalid = "captainVerify:invalid",
  CaptainVerifyValid = "captainVerify:valid",
  CaptainVerifyRiskyLowDeliverability = "captainVerify:riskyLowDeliverability",
  CaptainVerifyRiskyLowQuality = "captainVerify:riskyLowQuality",
  CaptainVerifyRisky = "captainVerify:risky",
  CaptainVerifyUnknown = "captainVerify:unknown",
}
