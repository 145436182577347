import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { RecordContextProvider } from "react-admin";
import type { EditFormWithDialogProps } from "../../../../../../display/EditFormDialog";
import { EditFormDialog } from "../../../../../../display/EditFormDialog";
import { ComfortSlider } from "./ComfortSlider";

export const EditComfortDialog = ({
  dialogProps,
  comfortType,
  initialValue,
  minValue,
  maxValue,
  recommendedValue,
  description,
  source,
}: EditFormWithDialogProps & {
  description: string;
  comfortType: "day" | "night";
  initialValue: number | undefined;
  minValue: number;
  maxValue: number;
  recommendedValue: number;
  source: "delta_day_temperature_in_c" | "delta_night_temperature_in_c";
}) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  return (
    <EditFormDialog
      dialogProps={dialogProps}
      getMutationAxiosConfig={(record) => {
        return {
          method: "PUT",
          url: `/devices/${record?.id}/comfort-config`,
          data: { value, comfortType },
        };
      }}
    >
      <Stack direction="column" spacing={2}>
        <Typography>{description}</Typography>
        <RecordContextProvider value={{ [source]: value }}>
          <ComfortSlider
            minValue={minValue}
            maxValue={maxValue}
            recommendedValue={recommendedValue}
            onChange={setValue}
            source={source}
          />
        </RecordContextProvider>
      </Stack>
    </EditFormDialog>
  );
};
