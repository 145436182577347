import DebugIcon from "@mui/icons-material/BugReport";
import RecoveryIcon from "@mui/icons-material/HealthAndSafety";
import HardResetIcon from "@mui/icons-material/PowerSettingsNew";
import StatusIcon from "@mui/icons-material/QueryStats";
import SecurityIcon from "@mui/icons-material/Security";
import FOTAIcon from "@mui/icons-material/Upgrade";
import { useNotify, useRecordContext } from "react-admin";
import { satisfies } from "semver";
import { useNotifyAction } from "../../../../actions/useNotifyAction";
import { ActionMenuItem } from "../../../../layout/actions-menu";
import { dataProvider } from "../../../../providers/data";
import { useAxiosMutation } from "../../../../providers/data-hooks/useAxiosQuery";
import type { Device } from "../../types";

const KICKMAKER_DEVICE_IMEI = 869_410_050_137_851;

export const HardResetAction = () => {
  const record = useRecordContext<Device>();

  const { mutate } = useNotifyAction(
    (id: string) => dataProvider.sendConfigFlags(id, "ReqHardReset"),
    { successMessage: "Hard reset requested" },
  );

  if (!record || record.majorHWVersion === 1) {
    return null;
  }

  return (
    <ActionMenuItem
      onClick={() => mutate(record.id)}
      label="Hard Reset"
      icon={<HardResetIcon />}
      disabled={!record.isObserving && record.IMEI !== KICKMAKER_DEVICE_IMEI}
    />
  );
};

export const RequestStatusAction = () => {
  const record = useRecordContext<Device>();

  const { mutate } = useNotifyAction(
    (id: string) => dataProvider.sendConfigFlags(id ?? "", "ReqStatus"),
    { successMessage: "Status requested" },
  );

  if (!record || record.majorHWVersion === 1) {
    return null;
  }

  return (
    <ActionMenuItem
      onClick={() => mutate(record.id)}
      label="Request Status"
      icon={<StatusIcon />}
      disabled={!record.isObserving && record.IMEI !== KICKMAKER_DEVICE_IMEI}
    />
  );
};

export const RequestRecoveryAction = () => {
  const record = useRecordContext<Device>();

  const { mutate } = useNotifyAction(
    (id: string) => dataProvider.sendConfigFlags(id ?? "", "ReqRecovery"),
    { successMessage: "Recovery requested" },
  );

  if (!record || record.majorHWVersion === 1) {
    return null;
  }

  return (
    <ActionMenuItem
      onClick={() => mutate(record.id)}
      label="Request Recovery"
      icon={<RecoveryIcon />}
      disabled={!record.isObserving && record.IMEI !== KICKMAKER_DEVICE_IMEI}
    />
  );
};

export const RequestDebugAction = () => {
  const record = useRecordContext<Device>();

  const { mutate } = useNotifyAction(
    (id: string) => dataProvider.sendConfigFlags(id ?? "", "ReqDebug"),
    { successMessage: "Debug requested" },
  );

  if (!record || record.majorHWVersion === 1) {
    return null;
  }

  return (
    <ActionMenuItem
      onClick={() => mutate(record.id)}
      label="Request Debug"
      icon={<DebugIcon />}
      disabled={!record.isObserving && record.IMEI !== KICKMAKER_DEVICE_IMEI}
    />
  );
};

export const RequestServerCaUpdateAction = () => {
  const record = useRecordContext<Device>();

  const { mutate } = useNotifyAction(
    (id: string) => dataProvider.sendConfigFlags(id ?? "", "ReqCertServ"),
    { successMessage: "Server CA update requested" },
  );

  if (
    !record ||
    (record.IMEI !== KICKMAKER_DEVICE_IMEI &&
      (record.majorHWVersion === 1 ||
        !satisfies(record.firmwareVersion ?? "0.0.0", ">=2.12 <3.0.0")))
  ) {
    return null;
  }

  return (
    <ActionMenuItem
      onClick={() => mutate(record.id)}
      label="Request Server CA Update"
      icon={<SecurityIcon />}
      disabled={!record.isObserving && record.IMEI !== KICKMAKER_DEVICE_IMEI}
    />
  );
};

export const RequestDeviceCertificateUpdateAction = () => {
  const record = useRecordContext<Device>();

  const { mutate } = useNotifyAction(
    (id: string) => dataProvider.sendConfigFlags(id ?? "", "ReqCertDev"),
    { successMessage: "Device certificate update requested" },
  );

  if (
    !record ||
    (record.IMEI !== KICKMAKER_DEVICE_IMEI &&
      (record.majorHWVersion === 1 ||
        !satisfies(record.firmwareVersion ?? "0.0.0", ">=2.12 <3.0.0")))
  ) {
    return null;
  }

  return (
    <ActionMenuItem
      onClick={() => mutate(record.id)}
      label="Request Device Certificate Update"
      icon={<SecurityIcon />}
      disabled={!record.isObserving && record.IMEI !== KICKMAKER_DEVICE_IMEI}
    />
  );
};

export const RequestOTAAction = () => {
  const notify = useNotify();
  const record = useRecordContext<Device>();

  const { mutate: requestOTA } = useAxiosMutation(
    {
      method: "POST",
      url: `/devices/${record?.id}/config-flags`,
    },
    {
      onSuccess: () => {
        notify("Firmware OTA requested...");
      },
      onError: (error) => {
        notify(`Request failed: ${error}`, { type: "error" });
      },
    },
  );

  if (
    record?.majorHWVersion === 1 ||
    record?.firmwareVersion === record?.targetFirmwareVersion
  ) {
    return null;
  }

  return (
    <ActionMenuItem
      label="Request OTA"
      disabled={!record?.isObserving && record?.IMEI !== KICKMAKER_DEVICE_IMEI}
      icon={<FOTAIcon />}
      onClick={requestOTA}
    />
  );
};
