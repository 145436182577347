import { Grid } from "@mui/material";
import { TextInput } from "react-admin";
import type { EditFormWithDialogProps } from "../../../display/EditFormDialog";
import { EditFormDialog } from "../../../display/EditFormDialog";
import type { EndUser } from "../type";

export function EditNotesDialog({ dialogProps }: EditFormWithDialogProps) {
  return (
    <EditFormDialog<EndUser, Pick<EndUser, "notes">>
      dialogProps={dialogProps}
      getMutationAxiosConfig={(record) => ({
        method: "PUT",
        url: `/end-users/${record?.id}/notes`,
      })}
      getFormRecordFromRecord={(record) => ({
        notes: record.notes,
      })}
    >
      <Grid container>
        <Grid item sm={12}>
          <TextInput label="Notes" source="notes" multiline rows={3} />
        </Grid>
      </Grid>
    </EditFormDialog>
  );
}
