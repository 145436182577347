import dayjs from "dayjs";
import type { Except } from "type-fest";
import { useAxiosQuery } from "../../providers/data-hooks/useAxiosQuery";
import type { DeviceConfig } from "./types";

export type Instruction = {
  setPoint: number;
  hysteresis: number;
  setPoint2?: number;
  hysteresis2?: number;
};

export const useScheduleData = (params: {
  config: Except<DeviceConfig, "id" | "createdAt" | "notes"> | undefined;
  startDate: Date;
  endDate: Date;
}) => {
  return useAxiosQuery<{
    instructions: { targetDate: string; instruction: Instruction }[];
  }>(
    {
      url: "/schedule",
      params: {
        startDate: dayjs(params.startDate).format("YYYY-MM-DD"),
        endDate: dayjs(params.endDate).format("YYYY-MM-DD"),
        config: JSON.stringify(params.config),
      },
    },
    {
      staleTime: 1000 * 60 * 2,
      enabled: !!params.config,
    },
  );
};
