import {
  Datagrid,
  List,
  ReferenceField,
  SearchInput,
  TextField,
} from "react-admin";
import { MarkdownPanel } from "../../layout/MarkdownPanel";
import { useSelectedColumns } from "../../layout/SelectColumns";
import { AddressField } from "../housing/fields/Address";
import { EndUserActions } from "./actions";
import { EndUserBulkActionButtons } from "./bulk-actions";
import { ContactsField } from "./fields/ContactsField";
import { NameField } from "./fields/NameField";

export const EndUserList = () => {
  const columns = useSelectedColumns({
    preferences: "endUsers.list.columns",
    columns: endUserColumns,
    omit: [],
    defaultColumns: ["name", "email", "mobilePhone", "landlinePhone"],
  });

  return (
    <List perPage={100} actions={<EndUserActions />} filters={filters}>
      <Datagrid
        bulkActionButtons={<EndUserBulkActionButtons />}
        expand={<MarkdownPanel source="notes" />}
        isRowExpandable={(record) => record.notes}
      >
        {columns}
      </Datagrid>
    </List>
  );
};

export const endUserColumns = {
  name: <NameField label="Name" />,
  emails: <ContactsField type="emails" label="Emails" sortable={false} />,
  phoneNumbers: (
    <ContactsField type="phoneNumbers" label="Phone numbers" sortable={false} />
  ),
  address: (
    <ReferenceField
      source="housingIds.0"
      reference="housings"
      label="Housing"
      sortable={false}
      link="show"
    >
      <AddressField />
    </ReferenceField>
  ),
  landlord: (
    <ReferenceField
      source="housingIds.0"
      reference="housings"
      label="Landlord"
      sortable={false}
    >
      <ReferenceField source="residenceId" reference="residences">
        <ReferenceField
          source="organizationId"
          reference="organizations"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
      </ReferenceField>
    </ReferenceField>
  ),
};

const filters = [<SearchInput source="q" alwaysOn />];
