import { ContactValidationStatus } from "@elax/business-types";
import { blueGrey, green, orange, purple, red } from "@mui/material/colors";
import { EnumField } from "../../../fields/EnumField";

export const validationStatusLabels = {
  [ContactValidationStatus.Unknown]: "Unknown",
  [ContactValidationStatus.Deleted]: "Deleted",
  [ContactValidationStatus.BackOfficeGivenByUser]: "Back Office: Given by user",
  [ContactValidationStatus.BackOfficeOtherReliable]:
    "Back Office: Other reliable",
  [ContactValidationStatus.BackOfficeOtherUnreliable]:
    "Back Office: Other unreliable",
  [ContactValidationStatus.ResidentAppUnverified]: "Resident App: Not verified",
  [ContactValidationStatus.InterventionInReport]: "Intervention: In Report",
  [ContactValidationStatus.BrevoDelivered]: "Brevo: Delivered",
  [ContactValidationStatus.BrevoUniqueOpened]: "Brevo: Unique Opened",
  [ContactValidationStatus.BrevoClick]: "Brevo: Click",
  [ContactValidationStatus.BrevoHardBounce]: "Brevo: Hard Bounce",
  [ContactValidationStatus.BrevoInvalidEmail]: "Brevo: Invalid Email",
  [ContactValidationStatus.BrevoError]: "Brevo: Error",
  [ContactValidationStatus.BrevoSpam]: "Brevo: Spam",
  [ContactValidationStatus.BrevoUnsubscribed]: "Brevo: Unsubscribed",
  [ContactValidationStatus.BrevoBlocked]: "Brevo: Blocked",
  [ContactValidationStatus.AuthOTPVerified]: "Auth: OTP Verified",
  [ContactValidationStatus.CaptainVerifyInvalid]: "Captain Verify: Invalid",
  [ContactValidationStatus.CaptainVerifyValid]: "Captain Verify: Valid",
  [ContactValidationStatus.CaptainVerifyRiskyLowDeliverability]:
    "Captain Verify: Risky / Low Deliverability",
  [ContactValidationStatus.CaptainVerifyRiskyLowQuality]:
    "Captain Verify: Risky / Low Quality",
  [ContactValidationStatus.CaptainVerifyRisky]: "Captain Verify: Risky",
  [ContactValidationStatus.CaptainVerifyUnknown]: "Captain Verify: Unknown",
};

export const ContactValidationStatusField = ({ label }: { label?: string }) => (
  <EnumField
    defaultColor={blueGrey[300]}
    colors={{
      [ContactValidationStatus.Deleted]: red[500],
      [ContactValidationStatus.BackOfficeGivenByUser]: green[500],
      [ContactValidationStatus.BackOfficeOtherReliable]: green[500],
      [ContactValidationStatus.BackOfficeOtherUnreliable]: blueGrey[500],
      [ContactValidationStatus.ResidentAppUnverified]: blueGrey[500],
      [ContactValidationStatus.BrevoDelivered]: green[500],
      [ContactValidationStatus.BrevoUniqueOpened]: green[500],
      [ContactValidationStatus.BrevoClick]: green[500],
      [ContactValidationStatus.BrevoHardBounce]: purple[500],
      [ContactValidationStatus.BrevoInvalidEmail]: purple[500],
      [ContactValidationStatus.BrevoBlocked]: purple[500],
      [ContactValidationStatus.BrevoError]: orange[500],
      [ContactValidationStatus.BrevoSpam]: orange[500],
      [ContactValidationStatus.BrevoUnsubscribed]: orange[500],
      [ContactValidationStatus.AuthOTPVerified]: green[500],
      [ContactValidationStatus.CaptainVerifyInvalid]: purple[500],
      [ContactValidationStatus.CaptainVerifyValid]: green[500],
      [ContactValidationStatus.CaptainVerifyRiskyLowDeliverability]:
        orange[500],
      [ContactValidationStatus.CaptainVerifyRiskyLowQuality]: orange[500],
      [ContactValidationStatus.CaptainVerifyRisky]: orange[500],
      [ContactValidationStatus.CaptainVerifyUnknown]: orange[500],
    }}
    labels={validationStatusLabels}
    label={label || "Validation Status"}
    source="status"
  />
);
