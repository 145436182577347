import BookmarkIcon from "@mui/icons-material/Bookmark";
import CancelIcon from "@mui/icons-material/Cancel";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import LoopIcon from "@mui/icons-material/Loop";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Alert, Box, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  NumberField,
  ReferenceField,
  Show,
  TextField,
  WithRecord,
} from "react-admin";
import { BalancingBidIcon } from "..";
import { FieldGrid } from "../../../display/FieldGrid";
import { FieldItem } from "../../../display/FieldItem";
import { JsonField } from "../../../fields/JsonField";
import { RoundedNumberField } from "../../../fields/RoundedNumberField";
import { BalancingBidActionsMenu } from "../actions";
import { BalancingBidCancelledField } from "../fields/BalancingBidCancelledField";
import type { BalancingBid, BalancingBidSentDocument } from "../types";

export const BalancingBidShow = () => {
  return (
    <Show actions={<BalancingBidActionsMenu />}>
      <Box padding={2}>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <BalancingBidIcon fontSize="large" />
          <FunctionField<BalancingBid>
            render={(record) =>
              "Offre d'ajustement du " +
              dayjs(record.formattedDate).format("DD/MM/YYYY")
            }
            variant="h4"
          />
          <BalancingBidCancelledField fontSize="large" />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <ReferenceField
            reference="demand-response-pools"
            source="poolId"
            link={false}
          >
            <ReferenceField
              reference="demand-response-entities"
              source="entity"
              link="show"
            >
              <TextField source="name" variant="h5" />
            </ReferenceField>
          </ReferenceField>
        </Stack>

        <Typography variant="h6" mt={2}>
          Parameters
        </Typography>
        <FieldGrid>
          <FieldItem label="Status" icon={<BookmarkIcon />}>
            <TextField source="status" />
          </FieldItem>

          <FieldItem label="Cancelled" icon={<CancelIcon />}>
            <DateField source="cancelledAt" showTime={true} />
          </FieldItem>

          <FieldItem label="Min duration" icon={<ScheduleIcon />}>
            <RoundedNumberField source="minDurationInMinutes" unit="min" />
          </FieldItem>

          <FieldItem label="Max duration" icon={<ScheduleIcon />}>
            <RoundedNumberField source="maxDurationInMinutes" unit="min" />
          </FieldItem>

          <FieldItem label="Dispatch time" icon={<HourglassTopIcon />}>
            <RoundedNumberField source="dispatchTimeInMinutes" unit="min" />
          </FieldItem>

          <FieldItem label="Max Energy" icon={<OfflineBoltIcon />}>
            <RoundedNumberField source="maxEnergyInMWh" unit="MWh" />
          </FieldItem>

          <FieldItem label="Max activations" icon={<LoopIcon />}>
            <RoundedNumberField source="maxActivations" />
          </FieldItem>
        </FieldGrid>

        <Typography variant="h6" mt={2}>
          Schedule
        </Typography>
        <ArrayField source="schedule">
          <Datagrid bulkActionButtons={false} rowClick={false}>
            <DateField
              source="startDate"
              label="Start time"
              showDate={false}
              showTime={true}
              variant="body1"
            />
            <DateField
              source="endDate"
              label="End time"
              showDate={false}
              showTime={true}
              variant="body1"
            />
            <TextField label="Type" source="type" />
            <NumberField
              label="Price (€/MW)"
              source="priceInEuros"
              variant="body1"
            />
          </Datagrid>
        </ArrayField>

        <Box my={2}>
          <JsonField
            source="powerSchedule"
            reactJsonOptions={{
              name: "Power schedule",
              collapsed: true,
            }}
          />
        </Box>

        <Typography variant="h6" mt={2}>
          Orders
        </Typography>
        <ArrayField source="orders">
          <Datagrid
            bulkActionButtons={false}
            rowClick={false}
            empty={<Typography variant="body2">No orders</Typography>}
          >
            <TextField source="orderId" label="Order ID" variant="body1" />
            <TextField source="type" label="Type" variant="body1" />
            <DateField
              source="requestDate"
              label="Request time"
              showDate={false}
              showTime={true}
              variant="body1"
            />
            <DateField
              source="startDate"
              label="Activation start"
              showDate={false}
              showTime={true}
              variant="body1"
            />
            <DateField
              source="endDate"
              label="Activation end"
              showDate={false}
              showTime={true}
              variant="body1"
            />
            <DateField
              source="cancelDate"
              label="Cancelled?"
              showTime={true}
              variant="body1"
            />
            <TextField
              source="cancelReason"
              label="Cancel reason"
              variant="body1"
            />
          </Datagrid>
        </ArrayField>

        <Typography variant="h6" mt={2}>
          Documents
        </Typography>
        <ArrayField source="sentDocuments">
          <Datagrid
            bulkActionButtons={false}
            rowClick={false}
            empty={<Typography variant="body2">No documents</Typography>}
          >
            <DateField
              source="date"
              label="Creation date"
              showDate={true}
              showTime={true}
              variant="body1"
            />
            <TextField source="name" label="Filename" variant="body1" />
            <FunctionField<BalancingBidSentDocument>
              label="Link"
              render={(record) =>
                dayjs(record.tempUrl.expirationDate).isAfter(dayjs()) ? (
                  <a href={record.tempUrl.url} target="_blank" rel="noreferrer">
                    Open (expires at{" "}
                    {dayjs(record.tempUrl.expirationDate).format("HH:mm")})
                  </a>
                ) : (
                  <Typography>Link expired, please refresh page</Typography>
                )
              }
            />
          </Datagrid>
        </ArrayField>

        <WithRecord<BalancingBid>
          render={(record) =>
            record.lastSubmission &&
            record.lastSubmission.errorMessages.length > 0 && (
              <>
                <Typography variant="h6" mt={2}>
                  Last submission (
                  <DateField source="lastSubmission.date" showTime={true} />)
                </Typography>
                <Alert severity="error">
                  <ul>
                    {record.lastSubmission.errorMessages.map(
                      (message, index) => (
                        <li key={index}>{message}</li>
                      ),
                    )}
                  </ul>
                </Alert>
              </>
            )
          }
        />
      </Box>
    </Show>
  );
};
