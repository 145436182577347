import type { Instruction } from "../../../../../device-config/useScheduleData";

export const getScheduleSeries = (
  instructions: { targetDate: string; instruction: Instruction }[],
  startDate: Date,
  endDate: Date,
) => {
  const series = instructions.map(({ instruction, targetDate }) => ({
    x: new Date(targetDate).getTime(),
    y: instruction.setPoint,
    custom: instruction,
  }));

  if (series.length === 0) {
    return series;
  }

  return [
    {
      ...series.at(-1),
      x: startDate.getTime(),
    },
    ...series,
    {
      ...series.at(-1),
      x: endDate.getTime(),
    },
  ];
};
