import { Mode } from "@elax/business-types";
import { useRecordContext } from "react-admin";
import { useWatch } from "react-hook-form";
import type { Device } from "../../devices/types";
import { ScheduleParameters } from "./schedule";
import { SetPointParamsToString, StaticModeParameters } from "./set-point";

const parametersToString: Record<
  Mode,
  (params: any, majorHWVersion: 1 | 2 | 3) => string
> = {
  [Mode.SET_POINT]: SetPointParamsToString,
  [Mode.OBSERVATION]: SetPointParamsToString,
  [Mode.DEPRECATED_PRE_OBSERVATION]: SetPointParamsToString,
  [Mode.SMART]: () => "",
  [Mode.DEPRECATED_SMART_FLEX]: () => "",
  [Mode.DEPRECATED_SMART_FLEX_v2]: () => "",
  [Mode.SMART_ENERGY]: () => "",
  [Mode.SCHEDULE]: () => "",
};

export const Parameters = ({
  device,
  source,
}: {
  device?: Device;
  isHPHC: boolean;
  source: string;
}) => {
  const mode = useWatch({
    name: source.includes(".") ? `${source.split(".")[0]}.mode` : "mode",
  }) as Mode;

  if (!mode) {
    return <></>;
  }

  if (
    mode === Mode.SET_POINT ||
    mode === Mode.OBSERVATION ||
    mode === Mode.DEPRECATED_PRE_OBSERVATION
  ) {
    return (
      <StaticModeParameters
        majorHWVersion={device?.majorHWVersion}
        source={source}
      />
    );
  }

  if (mode === Mode.SCHEDULE) {
    return (
      <ScheduleParameters
        majorHWVersion={device?.majorHWVersion}
        source={source}
      />
    );
  }

  if (
    mode === Mode.SMART ||
    mode === Mode.DEPRECATED_SMART_FLEX ||
    mode === Mode.DEPRECATED_SMART_FLEX_v2 ||
    mode === Mode.SMART_ENERGY
  ) {
    return null;
  }

  exhaustivityCheck(mode);

  return null;
};

const exhaustivityCheck = (mode: string) => {
  throw new Error("Not implemented mode: " + mode);
};

export const ParametersField = ({
  majorHWVersion,
}: {
  label?: string;
  majorHWVersion: 1 | 2 | 3;
}) => {
  const record = useRecordContext();

  if (!record?.mode) {
    return <></>;
  }

  return (
    <>
      {parametersToString[record.mode as Mode](
        record.parameters || {},
        majorHWVersion,
      )}
    </>
  );
};
