import { useGetManyReference, useRecordContext } from "react-admin";

export const useLastDeviceConfig = () => {
  const record = useRecordContext();

  const { data } = useGetManyReference(
    "device-configs",
    {
      target: "deviceId",
      id: record?.id,
      pagination: { perPage: 1, page: 1 },
      sort: { field: "createdAt", order: "DESC" },
    },
    { enabled: Boolean(record) },
  );

  if (!data || data.length === 0) {
    return null;
  }

  return data[0];
};
