import { Typography } from "@mui/material";
import { IfCanAccess, useCanAccess } from "@react-admin/ra-rbac";
import { useEffect } from "react";
import { Title, useRedirect } from "react-admin";
import { InterventionsDashboard } from "./Interventions";

export const Dashboard = () => {
  const { canAccess, isLoading } = useCanAccess({
    action: "read",
    resource: "dashboard",
  });
  const redirect = useRedirect();

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (canAccess === false) {
      redirect("/devices");
    }
  }, [canAccess, isLoading, redirect]);

  return (
    <>
      <Title title="Dashboard" />
      <IfCanAccess resource="dashboard" action="read">
        <Typography variant="h4" sx={{ marginTop: 2 }}>
          Dashboard
        </Typography>
        <InterventionsDashboard />
      </IfCanAccess>
    </>
  );
};
