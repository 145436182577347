import { Mode } from "@elax/business-types";
import { IfCanAccess, canAccess } from "@react-admin/ra-rbac";
import { pickBy } from "lodash";
import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  FilterButton,
  List,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  usePermissions,
} from "react-admin";
import type { DatagridProps } from "react-admin";
import { useBulkActionFilter } from "../../actions/useBulkActionFilter";
import { InlineList } from "../../fields/InlineListField";
import { OptimizedReferenceOneField } from "../../fields/OptimizedReferenceOneField";
import { EnumFilter } from "../../filters/EnumFilter";
import { IdsFilter } from "../../filters/IdsFilter";
import RangeSliderFilter from "../../filters/RangeSliderFilter";
import {
  SelectColumnsButton,
  useSelectedColumns,
} from "../../layout/SelectColumns";
import { ActionsMenu } from "../../layout/actions-menu";
import { LatestCriticalAlert } from "../alerts/fields/LatestAlert";
import TagArrayField from "../device-tags/fields/TagArrayField";
import TagField from "../device-tags/fields/TagField";
import { ExportDevicesOrEndUsers } from "../end-users/actions/Export";
import { NameField } from "../end-users/fields/NameField";
import { heatingStates } from "../heater/fields/HeatingStateField";
import { DeviceBulkActionButtons } from "./bulk-actions";
import { AssignFirmware } from "./bulk-actions/AssignFirmware";
import { CertificateField } from "./fields/CertificateField";
import {
  ContractTypeField,
  contractTypeLabels,
} from "./fields/ContractTypeField";
import { DeviceLastProjectField } from "./fields/DeviceLastProjectField";
import { ObserveField } from "./fields/Observe";
import { SafeReferenceOneField } from "./fields/SafeReferenceOneField";
import { SelectOrganization } from "./fields/SelectOrganization";
import { SelectProject } from "./fields/SelectProject";
import { DeviceStatusField } from "./fields/Status";
import { FirmwareVersionFilter } from "./filters/FirmwareVersion";
import { DeviceStatus } from "./types";

const defaultColumns = ["name", "pilotMode", "groups", "latestMessage"];
export const DeviceList = () => {
  return (
    <List
      perPage={20}
      filters={deviceFilters}
      sort={{ field: "updatedAt", order: "DESC" }}
      actions={<DeviceActions />}
    >
      <DevicesDatagrid />
    </List>
  );
};

const DeviceActions = () => {
  const permittedDeviceListColumns = usePermittedDeviceListColumns();
  const filter = useBulkActionFilter();

  return (
    <TopToolbar>
      <IfCanAccess resource="devices.filters" action="read">
        <FilterButton />
      </IfCanAccess>
      <SelectColumnsButton
        preference="devices.list.columns"
        columns={permittedDeviceListColumns}
      />
      <ActionsMenu>
        <IfCanAccess resource="end-users" action="read">
          <ExportDevicesOrEndUsers resource="devices" />
        </IfCanAccess>
        <AssignFirmware filter={filter} />
      </ActionsMenu>
    </TopToolbar>
  );
};

export const DevicesDatagrid = ({ children, ...props }: DatagridProps) => {
  const permittedDeviceListColumns = usePermittedDeviceListColumns();
  const columns = useSelectedColumns({
    preferences: "devices.list.columns",
    columns: permittedDeviceListColumns,
    omit: [],
    defaultColumns,
  });

  return (
    <Datagrid bulkActionButtons={<DeviceBulkActionButtons />} {...props}>
      {columns}
      {children}
    </Datagrid>
  );
};

const usePermittedDeviceListColumns = () => {
  const { permissions, isLoading } = usePermissions();
  if (isLoading) return {};
  return pickBy(deviceListColumns, (element) => {
    if (
      element.props.reference &&
      !canAccess({
        permissions,
        resource: element.props.reference,
        action: "read",
      })
    ) {
      return false;
    }
    return true;
  });
};

const deviceListColumns = {
  name: <TextField source="name" label="Name" />,
  IMEI: <TextField source="IMEI" label="IMEI" />,
  Client: (
    <ReferenceField reference="housings" source="housingId" label="Client">
      <ReferenceField reference="residences" source="residenceId">
        <ReferenceField
          reference="organizations"
          source="organizationId"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
      </ReferenceField>
    </ReferenceField>
  ),
  Project: <DeviceLastProjectField label="Last project" />,
  status: <DeviceStatusField label="Status" />,
  pilotMode: (
    <OptimizedReferenceOneField
      reference="device-configs"
      target="deviceId"
      sortField="createdAt"
      sortOrder="desc"
      label="Pilot Mode"
      sortable={false}
      fields={["mode", "createdAt", "deviceId"]}
    >
      <TextField source="mode" />
    </OptimizedReferenceOneField>
  ),
  actuatorMode: <TextField source="ActuatorMode" label="ActuatorMode" />,
  alert: <LatestCriticalAlert label="Ongoing alert" />,
  firmwareVersion: (
    <TextField source="firmwareVersion" label="Firmware Version" />
  ),
  targetFirmwareVersion: (
    <TextField source="targetFirmwareVersion" label="Target Firmware Version" />
  ),
  hardwareVersion: (
    <TextField source="VersionHardware" label="Hardware Version" />
  ),
  certificates: <CertificateField label="Certificates" />,
  groups: (
    <ReferenceArrayField
      reference="groups"
      source="group"
      label="Groups"
      sortable={false}
    >
      <InlineList source="groupName" />
    </ReferenceArrayField>
  ),
  isObserving: <ObserveField label="Observe State" />,
  latestMessage: (
    <DateField label="Latest Message" source="latestDataFrame.ts" showTime />
  ),
  latestTemperature1: (
    <NumberField label="T1 (°C)" source="latestDataFrame.temperature1" />
  ),
  latestTemperature2: (
    <NumberField label="T2 (°C)" source="latestDataFrame.temperature2" />
  ),
  latestSetPoint: (
    <NumberField label="Set Point 1" source="latestConfigAckFrame.set_point" />
  ),
  latestSetPoint2: (
    <NumberField label="Set Point 2" source="latestConfigAckFrame.set_point2" />
  ),
  contractType: <ContractTypeField label="Contract Type" />,
  bypassedRemoteSwitch: (
    <BooleanField source="bypassedRemoteSwitch" label="Télérupteur CC" />
  ),
  lastUpdate: <DateField label="Last update" source="updatedAt" showTime />,
  endUser: (
    <SafeReferenceOneField
      reference="end-users"
      target="housingIds"
      source="housingId"
      label="End user"
      sortable={false}
    >
      <NameField />
    </SafeReferenceOneField>
  ),
  heaterState: (
    <ReferenceField
      label="Heating state"
      reference="heaters"
      source="heater"
      link={false}
    >
      <SelectField
        source="state"
        choices={heatingStates.map((state) => ({
          id: state.selector,
          name: state.label,
        }))}
        fullWidth
      />
    </ReferenceField>
  ),
  pdl: (
    <ReferenceField
      label="PDL"
      reference="housings"
      source="housingId"
      link={false}
    >
      <TextField source="pdl" fullWidth />
    </ReferenceField>
  ),
  heaterPower: (
    <ReferenceField
      reference="heaters"
      source="heater"
      label="Heater power"
      link={false}
    >
      <TextField source="power" />
    </ReferenceField>
  ),
  heaterVolume: (
    <ReferenceField
      reference="heaters"
      source="heater"
      label="Heater volume"
      link={false}
    >
      <TextField source="volume" />
    </ReferenceField>
  ),
  tags: (
    <ReferenceArrayField label="Tags" reference="device-tags" source="tags">
      <TagArrayField />
    </ReferenceArrayField>
  ),
};

const deviceFilters = [
  <TextInput source="name" label="Name" alwaysOn />,
  <ReferenceInput
    reference="groups"
    label="Group"
    source="group"
    perPage={1000}
  >
    <AutocompleteInput optionText="groupName" />
  </ReferenceInput>,
  <ReferenceInput
    reference="residences"
    label="Residence"
    source="residenceId"
    perPage={1000}
  >
    <AutocompleteInput optionText="esi" />
  </ReferenceInput>,
  <SelectOrganization source="organizationId" />,
  <SelectProject source="projectId" />,
  <SelectInput
    label="Status"
    source="status"
    choices={Object.values(DeviceStatus).map((status) => ({
      id: status,
      name: status,
    }))}
  />,
  <EnumFilter
    label="Contract Type"
    source="contractType"
    categories={Object.entries(contractTypeLabels).map(([value, label]) => ({
      value,
      label,
    }))}
  />,
  <SelectInput
    label="Pilot Mode"
    source="mode"
    choices={Object.values(Mode).map((t) => ({ id: t, name: t }))}
  />,
  <FirmwareVersionFilter label="Firmware Version" source="firmwareVersion" />,
  <FirmwareVersionFilter
    label="Target Firmware Version"
    source="targetFirmwareVersion"
  />,
  // <ReferenceInput
  //   reference="hardware-versions"
  //   label="Hardware Version"
  //   source="VersionHardware"
  // >
  //   <SelectInput optionText="name" />
  // </ReferenceInput>,
  <EnumFilter
    label="Hardware Version"
    source="VersionHardware"
    categories={[
      { label: "1", value: { $regex: "^1" } },
      { label: "2", value: { $regex: "^2." } },
      { label: "3", value: { $regex: "^3." } },
    ]}
  />,
  <BooleanInput source="bypassedRemoteSwitch" label="Télérupteur CC" />,
  <SelectInput
    label="Heating state"
    source="heater.state"
    choices={heatingStates.map((state) => ({
      id: state.selector,
      name: state.label,
    }))}
    fullWidth
  />,
  <IdsFilter source="id" label="Ids (link only)" />,
  <RangeSliderFilter
    source="heater.volume"
    label="Heater volume"
    min={0}
    max={3400}
    step={50}
  />,
  <RangeSliderFilter
    source="heater.power"
    label="Heater power"
    min={0}
    max={24_000}
    step={500}
  />,
  <ReferenceInput label="Tags" source="tags" reference="device-tags">
    <AutocompleteInput
      optionText={<TagField />}
      inputText={(item) => item.name}
      size="small"
    />
  </ReferenceInput>,
];
