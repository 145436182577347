import dayjs from "dayjs";
import {
  Datagrid,
  LinearProgress,
  RecordContextProvider,
  TextField,
  useGetManyReference,
  useRecordContext,
} from "react-admin";
import { useDemandResponseEntityDetails } from "../../../../providers/data-hooks/useDemandResponseEntityDetails";
import { TemperatureChart } from "../../../devices/show/tabs/data/charts/Temperature";
import { AddressField } from "../../../housing/fields/Address";
import { HousingStatusField } from "../../../housing/fields/Status";
import type { DemandResponseEntity } from "../../type";

export const DemandResponseEntityHousingsTab = () => {
  const record = useRecordContext<DemandResponseEntity>();
  const { data, isLoading } = useDemandResponseEntityDetails(record?.id);

  return (
    <Datagrid
      data={data?.housingsSample ?? []}
      isLoading={isLoading}
      bulkActionButtons={false}
      expand={<ExpandHousing />}
      sort={{ field: "pdl", order: "DESC" }}
      rowClick={(id) => `/housings/${id}/show`}
    >
      <TextField label="Unit ID" source="unitId" />
      <TextField label="PDL" source="pdl" />
      <AddressField label="Address" />
      <HousingStatusField label="Status" />
    </Datagrid>
  );
};

const ExpandHousing = () => {
  const housing = useRecordContext();
  const { data } = useGetManyReference(
    "devices",
    {
      target: "housingId",
      id: housing?._id,
    },
    { enabled: Boolean(housing) },
  );

  if (!data) {
    return <LinearProgress />;
  }

  return (
    <>
      {data.map((device) => (
        <RecordContextProvider value={device}>
          <TemperatureChart
            key={device.id}
            device={device}
            startDate={dayjs().startOf("week").toDate()}
            endDate={dayjs().endOf("week").toDate()}
            paramsOptions={{}}
            cursor={Date.now()}
          />
        </RecordContextProvider>
      ))}
    </>
  );
};
