import type { SliderValueLabelProps } from "@mui/material";
import { Slider, Tooltip, Typography } from "@mui/material";
import { blue, teal } from "@mui/material/colors";
import { useRecordContext } from "react-admin";

type ComfortSliderProps = {
  minValue: number;
  maxValue: number;
  recommendedValue: number;
  onChange?: (deltaValue: number) => void;
  source: "delta_day_temperature_in_c" | "delta_night_temperature_in_c";
};

export const ComfortSlider = ({
  minValue,
  maxValue,
  recommendedValue,
  onChange,
  source,
}: ComfortSliderProps) => {
  const record = useRecordContext<{
    delta_day_temperature_in_c?: number;
    delta_night_temperature_in_c?: number;
  }>();

  if (!record) {
    return null;
  }

  const value = Math.min(
    Math.max(recommendedValue + (record[source] ?? 0), minValue),
    maxValue,
  );
  const left =
    (Math.min(recommendedValue, value) - minValue) / (maxValue - minValue);
  const size = Math.abs(value - recommendedValue) / (maxValue - minValue);
  const isDisabled = !onChange;

  return (
    <Slider
      value={value}
      onChange={
        onChange &&
        ((_, newValue) => onChange((newValue as number) - recommendedValue))
      }
      disabled={isDisabled}
      min={minValue}
      max={maxValue}
      defaultValue={recommendedValue}
      step={1}
      sx={{ minWidth: 100 }}
      marks={[
        {
          value: minValue,
          label: <Typography variant="caption">{minValue}°C</Typography>,
        },
        { value: recommendedValue, label: <RecoCursor left={left} /> },
        {
          value: maxValue,
          label: <Typography variant="caption">{maxValue}°C</Typography>,
        },
      ]}
      valueLabelDisplay="auto"
      slotProps={{
        track: {
          style: {
            left: `${left * 100}%`,
            width: `${size * 100}%`,
            color: blue[800],
          },
        },
        thumb: {
          style: {
            height: 15,
            width: 15,
            backgroundColor: blue[800],
          },
        },
      }}
      slots={{
        valueLabel: TooltipLabel,
      }}
    />
  );
};

const TooltipLabel = (
  props: SliderValueLabelProps & { ownerState: { defaultValue: number } },
) => {
  const { children, value, ownerState } = props;
  const diff = ownerState ? value - ownerState?.defaultValue : 0;

  return (
    <Tooltip
      placement="top"
      title={
        diff ? `${diff > 0 ? "+" : ""}${diff}°C (${value}°C)` : "Reco Elax"
      }
    >
      {children}
    </Tooltip>
  );
};

type RecoCursorProps = {
  left: number;
};

const RecoCursor = (props: RecoCursorProps) => {
  const size = 7;
  return (
    <Tooltip title="Reco Elax">
      <span
        style={{
          left: `${props.left * 100}%`,
          top: -25,
          width: "0px",
          height: "0px",
          borderStyle: "solid",
          borderWidth: `0 ${size}px ${size * 1.5}px ${size}px`,
          borderColor: `transparent transparent ${teal[400]} transparent`,
          transform: "translate(-50%, -50%) rotate(180deg)",
          position: "absolute",
        }}
      ></span>
    </Tooltip>
  );
};
