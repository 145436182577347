import { EnumField } from "../../../fields/EnumField";

export default function HeatingStateField({ label }: { label?: string }) {
  return (
    <EnumField
      label={label || "Heating state"}
      source="state"
      colors={heatingStateColors}
      labels={heatingStateLabels}
    />
  );
}

export const heatingStates = [
  {
    label: "Pas de calcairisation",
    color: "#4caf50",
    selector: "GOOD",
  },
  {
    label: "Calcairisation importante",
    color: "#ff9800",
    selector: "BAD",
  },
  {
    label: "Calcairisation critique",
    color: "#ff5722",
    selector: "VERY_BAD",
  },
  {
    label: "Problème électrique",
    color: "#f44336",
    selector: "ELECTRICAL_ISSUE",
  },
  {
    label: "Indéterminé",
    color: "#607d8b",
    selector: undefined,
  },
];

const heatingStateColors = Object.fromEntries(
  heatingStates.map((state) => [state.selector, state.color]),
);

export const heatingStateLabels = Object.fromEntries(
  heatingStates.map((state) => [state.selector, state.label]),
);
