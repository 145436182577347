import { Grid } from "@mui/material";
import { ListingImportField } from "../../../fields/ListingImportField";

export const ListingReport = () => {
  return (
    <Grid container>
      <Grid item xs={12} marginBottom={2}>
        <ListingImportField />
      </Grid>
    </Grid>
  );
};
