import DeviceConfigPersonaIcon from "@mui/icons-material/Diversity1";
import { DeviceConfigPersonaList } from "./List";
import { DeviceConfigPersonaShow } from "./Show";

export const deviceConfigPersonaResource = {
  list: DeviceConfigPersonaList,
  show: DeviceConfigPersonaShow,
  icon: DeviceConfigPersonaIcon,
  recordRepresentation: (record: { name: string }) => record.name,
};

export { default as DeviceConfigPersonaIcon } from "@mui/icons-material/Diversity1";
