import { Grid } from "@mui/material";
import { pick } from "lodash";
import { SelectInput, TextInput } from "react-admin";
import type { EditFormWithDialogProps } from "../../../display/EditFormDialog";
import { EditFormDialog } from "../../../display/EditFormDialog";
import type { EndUser } from "../type";

export function EditNamesDialog({ dialogProps }: EditFormWithDialogProps) {
  return (
    <EditFormDialog<
      EndUser,
      Pick<
        EndUser,
        | "title1"
        | "firstName1"
        | "lastName1"
        | "title2"
        | "firstName2"
        | "lastName2"
      >
    >
      dialogProps={dialogProps}
      getMutationAxiosConfig={(record) => ({
        method: "PUT",
        url: `/end-users/${record?.id}/names`,
      })}
      getFormRecordFromRecord={(record) =>
        pick(record, [
          "title1",
          "firstName1",
          "lastName1",
          "title2",
          "firstName2",
          "lastName2",
        ])
      }
    >
      <Grid container spacing={2}>
        <Grid item sm={4}>
          <SelectInput
            source="title1"
            fullWidth
            sx={{ marginTop: 0 }}
            choices={["M", "MME"].map((title) => ({ id: title, name: title }))}
          />
        </Grid>
        <Grid item sm={4}>
          <TextInput source="firstName1" fullWidth />
        </Grid>
        <Grid item sm={4}>
          <TextInput source="lastName1" fullWidth />
        </Grid>
        <Grid item sm={4}>
          <SelectInput
            source="title2"
            fullWidth
            sx={{ marginTop: 0 }}
            choices={["M", "MME"].map((title) => ({ id: title, name: title }))}
          />
        </Grid>
        <Grid item sm={4}>
          <TextInput source="firstName2" fullWidth />
        </Grid>
        <Grid item sm={4}>
          <TextInput source="lastName2" fullWidth />
        </Grid>
      </Grid>
    </EditFormDialog>
  );
}
